<script>
import ModalInsertCoupon from '@/views/AdminCenter/Payment/AddCredit/ModalInsertCoupon'
export default {
  components: { ModalInsertCoupon },
  name: 'BalanceCard',
  props: {
    balance: {
      type: [String, Number]
    },
    freeChargeDate: {
      type: String
    }
  },
  data () {
    return {
      showInsertCoupon: false
    }
  }
}
</script>
<template>
  <div class="balance-card--container">
    <div class="balance-card--upper">
      <div class="balance-card--balance-wrapper">
        <p class="balance-card--actual-balance-tip">{{ $t('admin.center.payment:balance.card.actual.balance') }}</p>
        <h2 class="balance-card--actual-balance-text">{{ balance }} {{ $t('admin.center.payment:balance.card.credits') }}</h2>
      </div>
      <div class="balance-card--right-wrapper">
        <span v-if="freeChargeDate" class="balance-card--right-tip"><v-icon class="mr-1" color="#2F6FED">mdi-information</v-icon>{{ $t('admin.center.payment:balance.card.free.charge', { date: freeChargeDate }) }}</span>
      </div>
    </div>
    <div class="balance-card--lower" v-if="!freeChargeDate">
      <div class="balance-card-lower--subscription">
        <v-btn class="btn transform-unset" dark :color="getPrimaryColor" @click="$router.push({ name: 'business.admin.center.payment.add.credits' })">
          <v-icon class="mr-2" size="18px" color="#fff">mdi-cached</v-icon>
          {{ $t('admin.center.payment:balance.card.subscription') }}
        </v-btn>
      </div>
      <div class="balance-card-lower--insert">
        <v-btn class="btn transform-unset" outlined dark :color="getPrimaryColor" @click="showInsertCoupon = true">{{ $t('admin.center.payment:balance.card.insert.cupom') }}</v-btn>
        <v-btn class="btn transform-unset ml-4" dark :color="getPrimaryColor" @click="$router.push({ name: 'business.admin.center.payment.add.credits' })"><v-icon class="mr-2" size="18px" color="#fff">mdi-cart</v-icon>{{ $t('admin.center.payment:balance.card.add.credit') }}</v-btn>
      </div>
    </div>
    <modal-insert-coupon v-if="showInsertCoupon" @close="showInsertCoupon = false; $emit('close')"></modal-insert-coupon>
  </div>
</template>
<style lang="scss">
.balance-card--container {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  .balance-card--upper {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    background: #FFFFFF;
    .balance-card--balance-wrapper {
      .balance-card--actual-balance-tip {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #A0A0A0;
        margin-bottom: 10px;
      }
      .balance-card--actual-balance-text {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 48px;
        color: #272730;
      }
    }
    .balance-card--right-wrapper {
      .balance-card--right-tip {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        background: linear-gradient(0deg, #F0F5FF, #F0F5FF), #FFFFFF;
        border-radius: 900px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 12px;
        color: #212121;
      }
    }
  }
  .balance-card--lower {
    display: flex;
    justify-content: space-between;
    padding: 16px 24px;
    background: #FAFAFA;
    border-top: 1px solid rgba(0, 0, 0, 0.06);
  }
}
</style>
