<script>
import InputField from '@/components/general/InputField'
export default {
  components: { InputField },
  name: 'ModalInsertCoupon',
  data () {
    return {
      coupon: null
    }
  },
  methods: {
    handleAddVoucher () {
      this.$store.dispatch('attempAddVoucher', { voucher: this.coupon }).then(({ data }) => {
        this.$emit('close')
      }).catch((err) => {
        if (err.code === 403 && err) this.$emit('close')
        else this.$emit('close')
      })
    }
  }
}
</script>
<template>
  <v-dialog :value="true" persistent no-click-animation width="780px" max-width="100%">
    <v-card class="modal-insert-coupon--container">
      <div class="modal-insert-coupon--header">
        <h2 class="modal-insert-coupon--title">{{ $t('modal.insert.coupon:title') }}</h2>
        <v-icon class="clickable" @click="$emit('close')">mdi-close</v-icon>
      </div>
      <div class="modal-insert-coupon--body">
        <input-field dense outlined hide-validation v-model="coupon"></input-field>
      </div>
      <div class="modal-insert-coupon--bottom">
        <v-btn class="btn transform-unset mr-4" outlined :color="getPrimaryColor" dark @click="$emit('close')">{{ $t('global:cancel') }}</v-btn>
        <v-btn class="btn transform-unset" :color="getPrimaryColor" :dark="!!coupon" :disabled="!coupon" @click="handleAddVoucher">{{ $t('global:add') }}</v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>
<style lang="scss">
.modal-insert-coupon--container {
  .modal-insert-coupon--header {
    background: #F8F9FB;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    justify-content: space-between;
    .modal-insert-coupon--title {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 24px;
      color: #212121;
    }
  }
  .modal-insert-coupon--body {
    padding: 24px;
  }
  .modal-insert-coupon--bottom {
    background: #F8F9FB;
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);
    padding: 24px;
    display: flex;
    justify-content: flex-end;
  }
 }
</style>
