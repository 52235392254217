<script>
import BalanceCard from '@/components/general/BalanceCard'
import FilterableTable from '@/components/general/FilterableTable'
export default {
  components: { BalanceCard, FilterableTable },
  name: 'CreditsHistory',
  data () {
    return {
      inicitialFilters: [],
      filters: [
        { type: 'datepicker', key: 'startDate', label: this.$t('transaction.history:filter.start') },
        { type: 'datepicker', key: 'endDate', label: this.$t('transaction.history:filter.end') },
        { type: 'selectfield', key: 'orderList', items: [{ text: this.$t('transaction.history:filter.all.transactions'), value: 'all' }, { text: this.$t('transaction.history:filter.credit.up'), value: 'orderUp' }, { text: this.$t('transaction.history:filter.credit.down'), value: 'orderDown' }], default: 'all' }
      ],
      tableHeaders: [
        { key: 'date', title: this.$t('transaction.history.table:column.1'), width: '120px' },
        { key: 'title', title: this.$t('transaction.history.table:column.2'), width: 'auto' },
        { key: 'credits', title: this.$t('transaction.history.table:column.3'), width: '120px', customColor: true }
      ],
      credits: '',
      paymentExemption: '',
      transactions: []
    }
  },
  methods: {
    formDate (date) {
      return `${date.split('-')[2]}/${date.split('-')[1]}/${date.split('-')[0]}`
    },
    formatTransaction (transaction) {
      switch (transaction.source) {
        case 'regular':
          return `${this.$t('payment.wallet:label.regular')}: ${transaction.code}`
        case 'cartao_pjbank':
          return `${this.$t('payment.wallet:label.regular')}: ${transaction.code}`
        case 'stripe':
          return `${this.$t('payment.wallet:label.regular')}: ${transaction.code}`
        case 'promo':
          return `${this.$t('payment.wallet:label.promo')}: ${transaction.code}`
        case 'paypal':
          return `${this.$t('payment.wallet:label.paypal')}: ${transaction.code}`
        case 'organic':
          return `${this.$t('payment.wallet:label.organic')}: ${transaction.candidate.fullName}/${transaction.position.title}`
        case 'invite':
          return `${this.$t('payment.wallet:label.organic')}: ${transaction.candidate.fullName}/${transaction.position.title}`
      }
    },
    handleGetWallet (filters) {
      this.inicitialFilters = filters
      this.$store.dispatch('attemptGetWallet', { filters }).then(({ data }) => {
        this.credits = data.credits
        this.paymentExemption = data.paymentExemption ? this.formDate(data.paymentExemption) : ''
        this.transactions = data.transactions.map(transaction => (
          {
            title: this.formatTransaction(transaction),
            date: this.formDate(transaction.date),
            credits: transaction.type === 'debit' ? `-${transaction.value}` : transaction.value,
            color: transaction.type === 'debit' ? 'red' : 'green'
          }
        ))
      })
    }
  },
  created () {
    this.$store.dispatch('attempNotifyTrack', { track: { event: 'wallet' } }).then(() => {})
  }
}
</script>
<template>
  <div class="credits-history--container">
    <balance-card
      :balance="credits"
      :freeChargeDate="paymentExemption"
      @close="handleGetWallet(inicitialFilters)"
    ></balance-card>
    <filterable-table
      :title="$t('transaction.history.table:title')"
      :headers="tableHeaders"
      :items="transactions"
      :filters="filters"
      @setFilters="handleGetWallet"
      @inicitialFilters="handleGetWallet"
      emptyBoxSuffix="credits"
    ></filterable-table>
  </div>
</template>
<style lang="scss">
.credits-history--container {
  padding: 0 0 80px;
}
</style>
